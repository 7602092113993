import React, { createContext, useContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebaseconfig";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Rai = createContext(); // creating context

const RaiContext = ({ children }) => {
  const [user, setUser] = useState(null);
  const [files, setFiles] = useState([]);

  const navigate = useNavigate();

  const logOut = () => {
    signOut(auth);
    navigate("/");
    toast.success("Log out successfully");
  };

  // to render app after login and sighup
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) setUser(user);
      else setUser(null);
    });
  }, []);

  return (
    <Rai.Provider value={{ user, files, setFiles, logOut }}>
      {children}
    </Rai.Provider>
  );
};

export default RaiContext;

export const RaiState = () => {
  return useContext(Rai);
};

import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, provider } from "../config/firebaseconfig";
import { createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import useOnlineStatus from "../utils/useOnlineStatus";
import { toast } from "react-toastify";

const SignIn = () => {
  const navigate = useNavigate();
  const errorRef = useRef(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showPassword, setShowPassword] = useState(true);

  // Email validation handler
  const handleemailchange = (e) => {
    setEmail(e.target.value);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(e.target.value)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  // Google sign-in handler
  const handelClick = () => {
    signInWithPopup(auth, provider)
      .then((res) => {
        toast.success(`Sign Up Successful. Welcome ${res.user.email}`);
        navigate("/function");
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Function for handling outside click event listner
  const handleClickOutside = (event) => {
    if (errorRef.current && !errorRef.current.contains(event.target)) {
      setEmailError("");
    }
  };

  const handleCreateAccount = async () => {
    try {
      const result = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      console.log(result);
      toast.success("Sigh up successfull");
      navigate("/function");
    } catch (err) {
      toast.error("Please enter details Correctly!");
      toast.error("Password should be at least 6 characters", err);
    }
  };

  useEffect(() => {
    // Attach document click handler on component mount
    document.addEventListener("click", handleClickOutside);

    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const onlineStatus = useOnlineStatus();

  if (onlineStatus === false)
    return (
      <h1 className=" font-bold text-lg">
        You are offline. Please turn on your internet connection.
      </h1>
    );
  return (
    <div className="flex flex-col lg:flex-row items-center lg:items-start">
      {/* Left section */}
      <div className="w-full lg:w-[523px] p-6 lg:ml-24">
        <div className="flex space-x-1 items-center w-24 h-9">
          <img className="w-[25px] h-[25px]" src="icon.png" alt="icon" />
          <h4 className="text-2xl font-bold font-poppins">R-ai</h4>
        </div>

        {/* Welcome Message */}
        <div className="mt-[25px] w-full lg:w-[523px] h-[170px] flex flex-col justify-between">
          <h2 className="font-semibold text-3xl lg:text-4xl poppins">
            Get Ready for the <br />{" "}
            <span style={{ color: "#010ED0" }}>AI</span> based data extraction
          </h2>
          <h1 className="font-poppins text-gray-500 font-medium text-lg lg:text-xl">
            Create your R-ai account
          </h1>
        </div>

        {/* Google Sign-up Button */}
        <button
          className="inline-flex items-center justify-center bg-blue-700 text-white rounded-xl w-full lg:w-[400px] h-[50px] mt-6 text-[16px] font-medium"
          onClick={handelClick}
        >
          <img
            src="Google_Icons.png"
            alt="Google Icon"
            className="w-[40px] h-[40px]"
          />
          Sign up with Google
        </button>

        {/* Divider */}
        <div className="mt-6 text-center w-full lg:w-[400px]">
          <h1 className="text-xs text-gray-500 font-medium">
            Or, register with your email
          </h1>
        </div>

        {/* Email and Password Inputs */}
        <div className="flex flex-col space-y-4 mt-4">
          <form className="flex flex-col">
            <label className="text-[16px] font-medium">Work Email</label>
            <div className="relative mt-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                <img className="w-4 h-4" src="email.png" alt="email" />
              </div>
              <input
                className="w-full lg:w-[400px] h-[40px] rounded-xl border border-gray-500 pl-10 pr-2 focus:outline-none"
                type="email"
                placeholder="Enter your Work email"
                value={email}
                onChange={handleemailchange}
              />
            </div>
            {emailError && (
              <span ref={errorRef} className="text-red-500 text-xs mt-1">
                {emailError}
              </span>
            )}
          </form>

          <form className="flex flex-col">
            <label className="text-[16px] font-medium">Password</label>
            <div className="relative mt-2 w-full lg:w-[400px]">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                <img className="w-4 h-5" src="password.png" alt="password" />
              </div>
              <input
                className="w-full h-[40px] rounded-xl border border-gray-500 pl-10 pr-10 focus:outline-none"
                type={showPassword ? "password" : "text"}
                placeholder="Enter your Password"
                onChange={handlePassword}
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">
                {showPassword ? (
                  <img
                    onClick={togglePasswordVisibility}
                    className="w-5 h-3 cursor-pointer"
                    src="eyeicon.png"
                    alt="eyeicon"
                  />
                ) : (
                  <img
                    onClick={togglePasswordVisibility}
                    className="w-5 h-4 cursor-pointer"
                    src="eyeicon2.png"
                    alt="eyeicon2"
                  />
                )}
              </div>
            </div>
          </form>
        </div>

        {/* Terms and Conditions */}
        <h1 className="mt-4 text-xs font-medium text-gray-500">
          By signing up, you agree to R-ai{" "}
          <span style={{ color: "#010ED0" }}>Terms of Service</span> and{" "}
          <span style={{ color: "#010ED0" }}>Privacy Policy</span>.
        </h1>

        <button
          className="inline-flex items-center justify-center bg-blue-700 text-white rounded-xl w-full lg:w-[400px] h-[50px] mt-4 text-[16px] font-medium"
          onClick={handleCreateAccount}
        >
          Create free account
        </button>

        {/* Log in link */}
        <h1 className="mt-6 font-poppins text-[16px] text-gray-500">
          Already have an account?{" "}
          <span
            onClick={handleLoginClick}
            style={{ color: "#010ED0", cursor: "pointer" }}
          >
            Log in
          </span>
        </h1>

        {/* Footer */}
        <h4 className="mt-2 text-[11px] text-gray-500">
          © Sthambh. All other programs and/or marks are the property of their
        </h4>
        <h4 className="text-xs text-gray-500 lg:ml-[80px]">
          respective owners. All rights reserved.
        </h4>
      </div>

      {/* Right section */}
      <div className="hidden lg:flex items-center justify-center w-full lg:w-[700px] h-auto mt-12 lg:ml-[180px]">
        <img
          src="Group.png"
          alt="AI-based data extraction features"
          className="max-w-full max-h-[700px] object-contain"
        />
      </div>
    </div>
  );
};

export default SignIn;

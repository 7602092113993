import React, { useState } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import { RaiState } from "../config/RaiContext";

const InvoiceParseThird = ({ answer, push, loading, files }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const { logOut } = RaiState();

  return (
    <aside className="col-span-3  bg-white border-l border-gray-200 drop-shadow-md">
      <button
        className=" mt-[20px] ml-[230px] text-red-600 font-medium"
        onClick={logOut}
      >
        Logout
      </button>
      <div className=" flex w-[250px] justify-between mt-[35px] ml-10 mb-2 font-medium text-base">
        <h1>{files[0]?.name}</h1>
        <button onClick={() => setIsOpen((prev) => !prev)}>
          <img className="w-6 h-6" src="3bar.png" alt="icon" />
        </button>
      </div>
      {/* dropdown  */}
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 z-50">
          <ul className="py-1 text-sm text-gray-700">
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
              Undo all changes
            </li>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
              Download
            </li>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
              Save File
            </li>
            <li className="px-4 py-2 hover:bg-gray-100 text-red-500 cursor-pointer">
              Delete
            </li>
          </ul>
        </div>
      )}
      {/* rendering two sections */}
      <div className=" flex items-center border-b mt-4">
        <div className="flex-1 cursor-pointer text-center pb-2 border-b-2 font-semibold">
          Final Results
        </div>
      </div>
      {loading ? (
        <BounceLoader className="m-[120px]" color="#1d39dd" />
      ) : (
        answer && (
          <div className="space-y-4">
            <div className=" flex justify-between ml-3 font-medium mt-2">
              <h4>Fields</h4>
              <img
                onClick={() => setIsExpanded((prev) => !prev)}
                className=" h-6 w-6"
                src="Arrowup.png"
                alt="up icon"
              />
            </div>
            {isExpanded && (
              <>
                <div className="h-[550px] w-full overflow-y-auto custom-scrollbar">
                  <ul className="p-3 space-y-2">
                    {push?.map((item, index) => {
                      const key = Object.keys(item)[0];
                      const value = item[key];
                      return (
                        <li
                          key={index}
                          className="flex justify-between items-start py-2"
                        >
                          {/* Key Section */}
                          <div className="flex gap-2 items-center">
                            <img
                              className="w-2 h-2"
                              src="half.png"
                              alt="half icon"
                              style={{ marginTop: "0.1rem" }} // Fine-tune the icon alignment
                            />
                            <span
                              style={{ color: "#5F6567" }}
                              className="text-gray-700 font-medium text-xs"
                            >
                              {key}
                            </span>
                          </div>
                          {/* Value Section */}
                          <span
                            className="font-medium text-xs text-right break-words"
                            style={{
                              maxWidth: "60%", // Ensure it wraps in its allocated space
                              wordWrap: "break-word",
                            }}
                          >
                            {value || "N/A"}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </>
            )}
          </div>
        )
      )}
    </aside>
  );
};

export default InvoiceParseThird;

import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../config/firebaseconfig";
import { toast } from "react-toastify";

const LogIn = () => {
  const navigate = useNavigate();
  const errorRef = useRef(null);

  const [showPassword, setShowPassword] = useState(true);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");

  // Email validation handler
  const handleemailchange = (e) => {
    setEmail(e.target.value);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(e.target.value)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Please Enter your email!");
    } else {
      try {
        await sendPasswordResetEmail(auth, email);
        toast.success("Password reset email sent! Please check your inbox.");
      } catch (err) {
        toast.error("Error sending password reset email");
        console.log(err);
      }
    }
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleSigninClick = () => {
    navigate("/");
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Function for handling outside click event listner
  const handleClickOutside = (event) => {
    if (errorRef.current && !errorRef.current.contains(event.target)) {
      setEmailError("");
    }
  };

  const handleLogin = async () => {
    if (!email && !password) {
      toast.error("Please fill all the fields");
    }

    try {
      const result = await signInWithEmailAndPassword(auth, email, password);

      console.log(result);

      console.log("Login successfully");
      toast.success("Login successfully");

      navigate("/function");

      setEmail("");
      setPassword("");
    } catch (err) {
      toast.error("Please enter details correctly");
      console.log("Error in Login ", err);
    }
  };

  useEffect(() => {
    // Attach document click handler on component mount
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    <div className="overflow-hidden mt-[36px] ml-[60px] lg:w-[calc(100vw-60px)] lg:h-[calc(100vh-36px)] flex flex-col lg:flex-row gap-2">
      <div className="w-full lg:w-[523px] h-full px-4 lg:px-0">
        {/* left section */}
        <div className="flex items-center w-[96px] h-[36px] gap-2">
          <img className="w-[33px] h-[33px]" src="icon.png" alt="icon" />
          <h4 className="text-2xl font-bold font-poppins">R-ai</h4>
        </div>

        {/* Welcome Message */}
        <div className="mt-[25px] w-full lg:w-[523px] h-[170px] flex flex-col justify-between">
          <h2 className="font-semibold text-2xl lg:text-4xl poppins">
            Get Ready for the <br />
            <span style={{ color: "#010ED0" }}>AI</span> based data extraction
          </h2>
          <p className="font-poppins text-gray-500 font-medium text-lg lg:text-xl">
            Login your R-ai account
          </p>
        </div>

        {/* Email Input */}
        <form className="flex flex-col mt-[60px] space-y-1">
          <label className="text-[16px] font-medium">Email</label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
              <img className="w-4 h-4" src="email.png" alt="email" />
            </div>
            <input
              className="w-full lg:w-[400px] h-[40px] rounded-xl border border-gray-500 pl-10 focus:outline-none"
              type="email"
              placeholder="Enter your Work email"
              onChange={handleemailchange}
            />
          </div>

          {emailError && (
            <span ref={errorRef} className="text-red-500 text-xs mt-1">
              {emailError}
            </span>
          )}
        </form>

        {/* Password Input */}
        <form className="flex flex-col mt-[15px] space-y-1">
          <label className="text-[16px] font-medium">Password</label>
          <div className="relative w-full lg:w-[400px]">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
              <img className="w-4 h-4" src="password.png" alt="password" />
            </div>

            <input
              className="w-full h-[40px] rounded-xl border border-gray-500 pl-10 pr-12 focus:outline-none"
              type={showPassword ? "password" : "text"}
              placeholder="Enter your Password"
              onChange={handlePassword}
            />

            <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">
              {showPassword ? (
                <img
                  className="w-4 h-3 cursor-pointer"
                  src="eyeicon.png"
                  alt="eyeicon"
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <img
                  className="w-4 h-4 cursor-pointer"
                  src="eyeicon2.png"
                  alt="eyeicon2"
                  onClick={togglePasswordVisibility}
                />
              )}
            </div>
          </div>
        </form>

        <h4
          style={{ color: "#010ED0" }}
          className="font-medium text-xs font-poppins pl-[290px] pt-1 cursor-pointer"
          onClick={handleResetPassword}
        >
          Forgot Password?
        </h4>

        <button
          className="inline-flex items-center justify-center text-white rounded-xl hover:bg-blue-700 w-full lg:w-[400px] h-[50px] mt-[30px] text-[16px] font-medium"
          style={{ backgroundColor: "#010ED0" }}
          onClick={handleLogin}
        >
          Log in
        </button>

        <h1 className="mt-[15px] font-poppins text-base text-gray-500 font-medium">
          Don't have any account?{" "}
          <span
            onClick={handleSigninClick}
            style={{ color: "#010ED0", cursor: "pointer" }}
          >
            Sign up
          </span>
        </h1>

        {/* Footer */}
        <h4 className="mt-[90px] text-xs pl-4">
          © Sthambh. All other programs and/or marks are the property of their
        </h4>
        <h4 className="text-xs pl-24">
          respective owners. All rights reserved.
        </h4>
      </div>

      {/* right section */}
      <div className="h-[300px] lg:h-[880px] w-full lg:w-[956px] flex justify-center lg:justify-end">
        <img
          src="Group.png"
          alt="AI-based data extraction features"
          className="max-w-full max-h-[200px] lg:max-h-[700px] object-contain lg:ml-[190px]"
        />
      </div>
    </div>
  );
};

export default LogIn;

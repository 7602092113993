import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBNv7ar473M798as-ZDLtDqp9lE-C0iP-Y",
  authDomain: "rai-ai-projects.firebaseapp.com",
  projectId: "rai-ai-projects",
  storageBucket: "rai-ai-projects.firebasestorage.app",
  messagingSenderId: "941970922617",
  appId: "1:941970922617:web:b63119dbbaab90556fc8a8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider };

import React, { useState } from "react";
import "@react-pdf-viewer/core/lib/styles/index.css";
import axios from "axios";
import { invoice_template, parse_invoice } from "../config/api";
import InvoiceParseThird from "./InvoiceParseThird";
import InvoiceParseSecond from "./InvoiceParseSecond";
import { RaiState } from "../config/RaiContext";
import { toast } from "react-toastify";

const InvoicePrase = () => {
  // const [files, setFiles] = useState([]);
  const [selectedImg, setSelectedImg] = useState(null);
  const [answer, setAnswer] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bool, setBool] = useState(false);
  const [text, setText] = useState("");
  const [formData, setFormData] = useState(new FormData());
  const [push, setPush] = useState([]);
  const [res, setRes] = useState([]);
  const [loader, setLoader] = useState(false);

  const { files, setFiles } = RaiState();

  // useEffect(() => {
  //   setFiles([]);
  //   console.log("Invoice mounted ");
  // }, []);

  const handleFileClick = () => {
    document.getElementById("file-upload").click();
  };

  // File upload handler
  const handleFileUpload = async (e) => {
    const newFiles = Array.from(e.target.files);

    const validExtensions = /\.(jpeg|jpg|png|gif|webp)$/i;
    const isvalid = newFiles.filter((file) => validExtensions.test(file.name));

    if (isvalid.length === 0) {
      return toast.error("Please upload valid image files only.");
    }

    const queryData = new FormData();
    const updatedFiles = [...files, ...newFiles];
    setFiles(updatedFiles);

    if (newFiles.length > 0 && !selectedImg) {
      setSelectedImg(URL.createObjectURL(newFiles[0]));
      queryData.append("file", newFiles[0]);
    }

    setLoader(true);
    setFormData(queryData);

    try {
      const response = await axios.get(invoice_template());
      if (response.status === 200) {
        setLoader(false);
        console.log(res);
        console.log(response?.data);
        const keys = response?.data?.map((item) => Object.keys(item)[0]); // keys are data of invoice template
        setAnswer(keys);
      } else {
        console.log("Error in uploading");
      }
    } catch (err) {
      console.log("Error in sending request to upload pdf", err);
    }
  };

  const removeFiles = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));

    if (selectedImg && fileName === selectedImg.name) {
      setSelectedImg(null);
    }
  };

  // handle second api call
  const handleSubmit = async () => {
    setLoading(true);
    // formData.append("target_json_format", JSON.stringify(res));
    try {
      const response = await axios.post(parse_invoice(), formData, {
        headers: {
          "Content-Type": "image/jpeg",
        },
      });
      if (response.status === 200) {
        setLoading(false);
        setPush(response?.data);
      } else {
        console.log("Error in final submit");
      }
    } catch (err) {
      console.log("Error in sending request to upload pdf", err);
    }
  };

  // Add text to answer and res array
  const handleanswer = () => {
    if (text.trim() === "") return;
    setAnswer((prevAnswers) => [...prevAnswers, text.trim()]);
    const newObject = { [text.trim()]: "" };
    setRes((prevres) => [...prevres, newObject]);
    setText("");
  };

  // Delete the data from answer array
  const handleDelete = (indexToDelete) => {
    setAnswer((prevAnswers) =>
      prevAnswers.filter((_, index) => index !== indexToDelete)
    );
    setRes((prevRes) => prevRes.filter((_, index) => index !== indexToDelete));
  };

  return (
    <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-12 h-screen">
      {/* First section */}
      <aside className="col-span-3 bg-white border-r border-gray-200  flex flex-col drop-shadow-md">
        <div className="flex items-center w-[96px] h-[36px] gap-2 mt-[20px] ml-[40px] sm:ml-[20px]">
          <img className="w-[33px] h-[33px]" src="icon.png" alt="icon" />
          <h4 className="text-2xl font-bold font-poppins">R-ai</h4>
        </div>

        <button
          className="bg-blue-600 text-white rounded-lg flex items-center justify-center h-[38px] w-[240px] gap-12 font-medium mt-12 sm:mt-6 ml-3"
          style={{ backgroundColor: "#010ED0" }}
          onClick={() => window.location.reload()}
        >
          Start New Chat
          <img className="w-6 h-6" src="Arrow.png" alt="arrow" />
        </button>

        <div className=" mt-8 space-y-3 ml-2">
          <div className="flex gap-5">
            <div
              className=" flex h-[30px] w-[30px] rounded-full items-center justify-center"
              style={{ backgroundColor: "#DEE0FF" }}
            >
              1
            </div>
            <div>
              <button
                className="rounded-lg flex items-center justify-center h-[38px] w-[204px] gap-3 font-medium"
                style={{ backgroundColor: "#010ED0", color: "white" }}
                onClick={handleFileClick}
              >
                <img className="w-6 h-6 " src="Upload1.png" alt="arrow" />
                Upload Image
                <input
                  onChange={handleFileUpload}
                  type="file"
                  id="file-upload"
                  class="hidden"
                  multiple
                />
              </button>
              <div className=" flex gap-2  mt-2">
                <img className="w-4 h-4" src="Frame.png" alt="frame" />
                <h4 className="text-sm">upload image only</h4>
              </div>
              <div className="flex flex-col w-auto h-[25px]">
                {files.map((file) => (
                  <div
                    key={file.name}
                    className="flex items-center border border-gray-200 rounded-full gap-7 h-[30px] p-2 mt-2"
                    style={{ backgroundColor: "#F5F5F5" }}
                  >
                    <img src="pdf.png" alt="pdf icon" className="w-4 h-4" />
                    <span
                      onClick={(file) =>
                        setSelectedImg(URL.createObjectURL(file))
                      }
                      className=" text-sm font-medium text-gray-700"
                    >
                      {file.name}
                    </span>
                    <button
                      onClick={() => removeFiles(file.name)}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      X
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* showing response from api */}
          {selectedImg ? (
            <>
              <div className=" flex gap-5">
                <div
                  className=" flex h-[30px] w-[30px] rounded-full items-center justify-center mt-3"
                  style={{ backgroundColor: "#DEE0FF" }}
                >
                  2
                </div>
                <div>
                  <h1
                    className=" text-base font-medium mt-3"
                    style={{ color: "#010ED0" }}
                  >
                    Invoice Fields
                  </h1>
                  <div className=" h-[350px] w-[300px] overflow-y-auto custom-scrollbar mt-2 space-y-3">
                    <button
                      className=" h-[40px] w-[200px] flex items-center justify-center rounded-lg gap-5"
                      style={{ backgroundColor: "#DEE0FF" }}
                      onClick={() => setBool((prev) => !prev)}
                    >
                      <img src="Plus.png" alt="plus icon" />
                      <h1 className=" font-medium text-blue-800">
                        Add New Field
                      </h1>
                    </button>
                    {bool && (
                      <>
                        <div className="  h-[40px] w-[200px] ">
                          <input
                            type="text"
                            placeholder="Enter Text"
                            value={text}
                            className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            onChange={(e) => setText(e.target.value)}
                            style={{ backgroundColor: "#F5F5F5" }}
                          />
                        </div>
                        <button
                          className="text-white rounded-lg  w-[85px] h-[36px] flex items-center justify-center"
                          style={{ backgroundColor: "#010ED0" }}
                          onClick={handleanswer}
                        >
                          Add Field
                        </button>
                      </>
                    )}
                    <p>
                      {answer.map((item, index) => (
                        <li
                          className=" text-xs font-extralight flex justify-between space-y-2"
                          key={index}
                        >
                          <h1 className=" font-medium text-sm">{item}</h1>
                          <img
                            className=" w-4 h-4 mr-6 cursor-pointer"
                            src="Delete1.png"
                            alt="delete icon"
                            onClick={() => handleDelete(index)}
                          />
                        </li>
                      ))}
                    </p>
                  </div>
                </div>
              </div>
              <div className=" flex gap-5">
                <div
                  className=" flex h-[30px] w-[30px] rounded-full items-center justify-center mt-3"
                  style={{ backgroundColor: "#DEE0FF" }}
                >
                  3
                </div>
                <button
                  style={{ backgroundColor: "#010ED0" }}
                  className=" h-[40px] w-[200px] rounded-xl text-white font-medium mt-3"
                  onClick={handleSubmit}
                >
                  Final submit
                </button>
              </div>
            </>
          ) : (
            <>
              <div className=" flex gap-5">
                <div
                  className=" flex h-[34px] w-[34px] rounded-full items-center justify-center"
                  style={{ backgroundColor: "#F5F5F5" }}
                >
                  2
                </div>
                <h1
                  className=" text-base font-medium"
                  style={{ color: "#5F6567" }}
                >
                  Invoice Fields
                </h1>
              </div>
              <div className=" flex gap-5">
                <div
                  className=" flex h-[34px] w-[34px] rounded-full items-center justify-center mt-3"
                  style={{ backgroundColor: "#F5F5F5" }}
                >
                  3
                </div>
                <h1
                  className=" text-base font-medium mt-3"
                  style={{ color: "#5F6567" }}
                >
                  Final Push
                </h1>
              </div>
            </>
          )}
        </div>
      </aside>

      {/* Second section */}
      <InvoiceParseSecond selectedImg={selectedImg} loader={loader} />

      {/* Third section */}
      <InvoiceParseThird
        answer={answer}
        push={push}
        loading={loading}
        files={files}
      />
    </div>
  );
};
export default InvoicePrase;

import React from "react";
import { useNavigate } from "react-router-dom";
import { RaiState } from "../config/RaiContext";

const Function = () => {
  const navigate = useNavigate();

  const { user, logOut } = RaiState();

  return (
    <div className="h-screen flex flex-col">
      {/* Logo and Title Section */}
      <div className="flex items-center max-w-full h-[36px] gap-2 mt-[47px] ml-[60px]">
        <img className="w-[33px] h-[33px]" src="icon.png" alt="icon" />
        <h4 className="text-2xl font-bold font-poppins">R-ai</h4>
        {user && (
          <div className="ml-auto mr-10">
            <button
              className=" text-red-500 text-base font-medium"
              onClick={logOut}
            >
              Logout
            </button>
          </div>
        )}
      </div>

      {/* Title */}
      <div className=" w-full h-[63px] md:mt-[120px] px-4 md:px-0">
        <h1 className="text-[24px]  md:text-[42px] font-semibold font-poppin text-center">
          <span style={{ color: "#010ED0" }}>Select</span> the function you want
          us to perform
        </h1>
      </div>

      {/*Cards Section */}

      <div className="w-full h-[450px]  flex justify-center">
        <div className=" h-[400px] w-[790px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 mt-8">
          <div
            className="border border-gray-300 h-[180px] w-[245px] rounded-2xl flex flex-col items-center justify-center shadow-sm hover:shadow-md  cursor-pointer"
            onClick={() => navigate("/analyse")}
          >
            <img
              className=" w-[81px] h-[90px] mb-4"
              src="AnalyzeData.png"
              alt="analyzedata"
            />
            <h3 className="text-xl font-medium text-gray-700">Analyse Data</h3>
          </div>
          <div className="border border-gray-300 h-[180px] w-[245px] rounded-2xl flex flex-col items-center justify-center shadow-sm hover:shadow-md cursor-pointer">
            <img
              className=" w-[81px] h-[90px] mb-4"
              src="Summarize.png"
              alt="analyzedata"
            />
            <h3 className="text-xl font-medium text-gray-700">Summarize</h3>
          </div>

          <div className="border border-gray-300 h-[180px] w-[245px] rounded-2xl flex flex-col items-center justify-center shadow-sm hover:shadow-md cursor-pointer">
            <img
              className=" w-[81px] h-[80px] mb-4"
              src="Document.png"
              alt="document"
            />
            <div>
              <h3 className="text-xl font-medium text-gray-700">
                Chat with the
              </h3>
              <h3 className="text-xl font-medium text-gray-700 pl-3">
                document
              </h3>
            </div>
          </div>

          <div
            className="border border-gray-300 h-[180px] w-[245px] rounded-2xl flex flex-col items-center justify-center shadow-sm hover:shadow-md cursor-pointer"
            onClick={() => navigate("/invoice")}
          >
            <img
              className=" w-[81px] h-[90px] mb-4"
              src="Invoice.png"
              alt="invoice"
            />
            <h3 className="text-xl font-medium text-gray-700">Invoice Parse</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Function;

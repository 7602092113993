import { Route, Routes } from "react-router-dom";
import "./App.css";
import SignIn from "./components/SignIn";
import LogIn from "./components/LogIn";
import Function from "./components/Function";
import Analyse from "./components/Analyse";
import InvoicePrase from "./components/InvoicePrase";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/login" element={<LogIn />} />
        <Route path="/function" element={<Function />} />
        <Route path="/analyse" element={<Analyse />} />
        <Route path="invoice" element={<InvoicePrase />} />
      </Routes>
    </div>
  );
}

export default App;

import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

const InvoiceParseSecond = ({ selectedImg, loader }) => {
  const [scale, setScale] = useState(1.0);
  const [rotation, setRotation] = useState(0);

  const zoomOut = () => setScale((prevScale) => Math.max(prevScale - 0.2, 0.5));
  const zoomIn = () => setScale((prevScale) => Math.min(prevScale + 0.2, 3.0));
  const rotateClockwise = () => setRotation((prev) => prev + 90);
  const rotateCounterClockwise = () => setRotation((prev) => prev - 90);

  return (
    <div class="col-span-6 bg-white items-center justify-center flex flex-col">
      <div className=" w-full flex h-[40px] justify-center shadow-md">
        <h1 className=" font-bold text-2xl">Invoice Prase</h1>
      </div>
      <div className="overflow-y-auto w-full flex flex-col-reverse items-center  h-[580px] mt-2 mb-2 custom-scrollbar">
        {selectedImg ? (
          loader ? (
            <div>
              <ClipLoader className=" mb-64" />
            </div>
          ) : (
            <div className="w-10/12 flex justify-center">
              <img
                src={selectedImg}
                alt="Preview"
                style={{
                  transform: `scale(${scale}) rotate(${rotation}deg)`,
                  transition: "transform 0.2s",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              />
            </div>
          )
        ) : (
          <p className="text-gray-500">
            No img selected. Upload and select a file to preview.
          </p>
        )}
      </div>

      {selectedImg && (
        <div
          style={{ backgroundColor: "#F5F5F5" }}
          className=" w-[454px] h-[50px] rounded-lg flex p-2 justify-center items-center gap-5"
        >
          <button onClick={zoomOut}>
            <img src="Zoomin.png" alt="Zoom Out" />
          </button>
          <input
            type="range"
            min="0.5"
            max="3"
            step="0.1"
            value={scale}
            onChange={(e) => setScale(parseFloat(e.target.value))}
            className="slider w-20"
          />
          <button onClick={zoomIn}>
            <img src="zoomout.png" alt="Zoom In" />
          </button>
          <span className="text-gray-600 font-medium">
            {Math.round(scale * 100)}%
          </span>
          <button>
            <img src="Preview.png" alt="Previous Page" />
          </button>
          <button onClick={rotateCounterClockwise}>
            <img src="Next.png" alt="Rotate Left" />
          </button>
          <button onClick={rotateClockwise}>
            <img src="Previous.png" alt="Rotate Right" />
          </button>
        </div>
      )}
    </div>
  );
};

export default InvoiceParseSecond;
